import React, { createContext, useState } from "react";
import { ACCESS_TOKEN, PROFILE, STORE_ID } from "../helpers/Constant";
import api from "../services/Api";

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);
const storeId = localStorage.getItem(STORE_ID);

const AuthContextProvider = ({ children }) => {
    const [isLogin, setIsLogin] = useState(!!auth);
    const [profile, setProfile] = useState(null);
    const [role, setRole] = useState();
    const [loading, setLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);
    const [selectedStore, setSelectedStore] = useState(storeId);

    const login = async (body) => {
        api.auth.login({ setLoading, body }, (response) => {
            localStorage.setItem(ACCESS_TOKEN, response.data.token);
            localStorage.setItem(PROFILE, JSON.stringify(response.data.user));
            setRole(response.data.user.role?.alias);
            setIsLogin(true);
        });
    };

    const selectStore = (id) => {
        setSelectedStore(id);
        localStorage.setItem(STORE_ID, id);
    };

    const getUserProfile = async () => {
        api.auth.getProfile(setProfileLoading, (response) => {
            localStorage.setItem(PROFILE, JSON.stringify(response.data));
            setProfile(response.data);
            setIsLogin(true);
            setRole(response.data.role?.alias);
            if (response.data.storeList?.length === 1) {
                selectStore(response.data.storeList[0]._id);
            }
        });
    };

    const logout = () => {
        setIsLogin(false);
        setSelectedStore(null);
        setProfile(null);
        localStorage.clear();
    };

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                profile,
                loading,
                profileLoading,
                role,
                permissions: profile ? profile.permissions : [],
                login,
                logout,
                getUserProfile,
                selectedStore,
                selectStore
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
