import React from "react";
import AuthContextProvider from "../contexts/AuthContextProvider";
import ProductContextProvider from "../contexts/ProductContextProvider";
import WarehouseContextProvider from "../contexts/WarehouseContextProvider";
import RoleContextProvider from "../contexts/RoleContextProvider";
import SaleContextProvider from "../contexts/SaleContext";
import PurchaseContextProvider from "../contexts/PurchaseContext";
import StaticPageContextProvider from "../contexts/StaticPageContextProvider";
import CustomerFeedbackContextProvider from "../contexts/CustomerFeedbackContextProvider";

const contextProviders = [
    AuthContextProvider,
    ProductContextProvider,
    WarehouseContextProvider,
    RoleContextProvider,
    SaleContextProvider,
    PurchaseContextProvider,
    StaticPageContextProvider,
    CustomerFeedbackContextProvider
];

export const ProviderInjection = ({ app }) => {
    contextProviders.forEach((Provider) => (app = <Provider>{app}</Provider>));
    return app;
};

export default ProviderInjection;
