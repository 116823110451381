import React, { createContext, useState } from "react";
import api from "../services/Api";

export const WarehouseContext = createContext("WarehouseContext");

const WarehouseContextProvider = ({ children }) => {
    const [warehouseList, setWarehouseList] = useState([]);
    const [loadingWarehouseList, setLoadingWarehouseList] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    const getWarehouseList = async (params) => {
        api.warehouse.getAll(
            { params, setLoading: setLoadingWarehouseList },
            (response) => {
                setWarehouseList(response.data.content);
                setTotalElements(response.data.totalElements);
            }
        );
    };

    return (
        <WarehouseContext.Provider
            value={{
                getWarehouseList,
                warehouseList,
                totalElements,
                loadingWarehouseList
            }}
        >
            {children}
        </WarehouseContext.Provider>
    );
};

export default WarehouseContextProvider;
