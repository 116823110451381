export const ROOT_PATH = "/";

export const LOGIN_PATH = `${ROOT_PATH}login`;
export const PROFILE_PATH = `${ROOT_PATH}profile`;

// products
export const PRODUCT_LIST_PATH = `${ROOT_PATH}product-list`;
export const ADD_PRODUCT_PATH = `${ROOT_PATH}add-product`;
export const EDIT_PRODUCT_PATH = `${ROOT_PATH}edit-product`;
export const DETAILS_PRODUCT_PATH = `${ROOT_PATH}product-details`;
// stores
export const STORE_LIST_PATH = `${ROOT_PATH}store-list`;
export const ADD_STORE_PATH = `${ROOT_PATH}add-store`;
export const EDIT_STORE_PATH = `${ROOT_PATH}edit-store`;

// generic
export const GENERIC_LIST_PATH = `${ROOT_PATH}generic-list`;
export const ADD_GENERIC_PATH = `${ROOT_PATH}add-generic`;
export const EDIT_GENERIC_PATH = `${ROOT_PATH}edit-generic`;

// customer
export const CUSTOMER_LIST_PATH = `${ROOT_PATH}customer-list`;
export const ADD_CUSTOMER_PATH = `${ROOT_PATH}add-customer`;
export const EDIT_CUSTOMER_PATH = `${ROOT_PATH}edit-customer`;
// customer transaction
export const CUSTOMER_TRANSACTION_LIST_PATH = `${ROOT_PATH}customer-transaction-list`;
export const ADD_CUSTOMER_TRANSACTION_PATH = `${ROOT_PATH}add-customer-transaction`;
export const EDIT_CUSTOMER_TRANSACTION_PATH = `${ROOT_PATH}edit-customer-transaction`;

// supplier
export const SUPPLIER_LIST_PATH = `${ROOT_PATH}supplier-list`;
export const ADD_SUPPLIER_PATH = `${ROOT_PATH}add-supplier`;
export const EDIT_SUPPLIER_PATH = `${ROOT_PATH}edit-supplier`;

// user
export const USER_LIST_PATH = `${ROOT_PATH}user-list`;
export const ADD_USER_PATH = `${ROOT_PATH}add-user`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-user`;

// suit
export const SUIT_LIST_PATH = `${ROOT_PATH}suit-list`;
export const ADD_SUIT_PATH = `${ROOT_PATH}add-suit`;
export const EDIT_SUIT_PATH = `${ROOT_PATH}edit-suit`;

// banner
export const BANNER_LIST_PATH = `${ROOT_PATH}banner-list`;
export const ADD_BANNER_PATH = `${ROOT_PATH}add-banner`;
export const EDIT_BANNER_PATH = `${ROOT_PATH}edit-banner`;

// company
export const COMPANY_LIST_PATH = `${ROOT_PATH}company-list`;
export const ADD_COMPANY_PATH = `${ROOT_PATH}add-company`;
export const EDIT_COMPANY_PATH = `${ROOT_PATH}edit-company`;

// faq
export const FAQ_LIST_PATH = `${ROOT_PATH}faq-list`;
export const ADD_FAQ_PATH = `${ROOT_PATH}add-faq`;
export const EDIT_FAQ_PATH = `${ROOT_PATH}edit-faq`;

// service
export const SERVICE_LIST_PATH = `${ROOT_PATH}service-list`;
export const ADD_SERVICE_PATH = `${ROOT_PATH}add-service`;
export const EDIT_SERVICE_PATH = `${ROOT_PATH}edit-service`;

// Gallery
export const GALLERY_LIST_PATH = `${ROOT_PATH}gallery-list`;
export const ADD_GALLERY_PATH = `${ROOT_PATH}add-gallery`;
export const EDIT_GALLERY_PATH = `${ROOT_PATH}edit-gallery`;

// testimonial
export const TESTIMONIAL_LIST_PATH = `${ROOT_PATH}testimonial-list`;
export const ADD_TESTIMONIAL_PATH = `${ROOT_PATH}add-testimonial`;
export const EDIT_TESTIMONIAL_PATH = `${ROOT_PATH}edit-testimonial`;

// job
export const JOB_LIST_PATH = `${ROOT_PATH}job-list`;
export const ADD_JOB_PATH = `${ROOT_PATH}add-job`;
export const EDIT_JOB_PATH = `${ROOT_PATH}edit-job`;

// team member
export const TEAM_MEMBER_LIST_PATH = `${ROOT_PATH}team-member-list`;
export const ADD_TEAM_MEMBER_PATH = `${ROOT_PATH}add-team-member`;
export const EDIT_TEAM_MEMBER_PATH = `${ROOT_PATH}edit-team-member`;

// tribute
export const TRIBUTE_LIST_PATH = `${ROOT_PATH}tribute-list`;
export const ADD_TRIBUTE_PATH = `${ROOT_PATH}add-tribute`;
export const EDIT_TRIBUTE_PATH = `${ROOT_PATH}edit-tribute`;

// Activity
export const ACTIVITY_LIST_PATH = `${ROOT_PATH}activity-list`;
export const ADD_ACTIVITY_PATH = `${ROOT_PATH}add-activity`;
export const EDIT_ACTIVITY_PATH = `${ROOT_PATH}edit-activity`;

// Comlimentary
export const COMPLIMENTARY_LIST_PATH = `${ROOT_PATH}complimentary-list`;
export const ADD_COMPLIMENTARY_PATH = `${ROOT_PATH}add-complimentary`;
export const EDIT_COMPLIMENTARY_PATH = `${ROOT_PATH}edit-complimentary`;

// Accommodation
export const ACCOMMODATION_LIST_PATH = `${ROOT_PATH}accommodation-list`;
export const ADD_ACCOMMODATION_PATH = `${ROOT_PATH}add-accommodation`;
export const EDIT_ACCOMMODATION_PATH = `${ROOT_PATH}edit-accommodation`;

// sale
export const SALE_LIST_PATH = `${ROOT_PATH}sale-list`;
export const ADD_SALE_PATH = `${ROOT_PATH}add-sale`;
export const EDIT_SALE_PATH = `${ROOT_PATH}edit-sale`;
export const SALE_RECORD_PATH = `${ROOT_PATH}sale-record`;
export const SALE_DETAILS_PATH = `${ROOT_PATH}sale-details`;
// purchase
export const SALE_PURCHASE_PATH = `${ROOT_PATH}purchase-list`;
export const ADD_PURCHASE_PATH = `${ROOT_PATH}add-purchase`;
export const EDIT_PURCHASE_PATH = `${ROOT_PATH}edit-purchase`;
export const PURCHASE_RECORD_PATH = `${ROOT_PATH}purchase-record`;
export const PURCHASE_DETAILS_PATH = `${ROOT_PATH}purchase-details`;
// role
export const ROLE_LIST_PATH = `${ROOT_PATH}role-list`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const EDIT_ROLE_PATH = `${ROOT_PATH}edit-role`;

// business
export const BUSINESS_LIST_PATH = `${ROOT_PATH}business-list`;
export const ADD_BUSINESS_PATH = `${ROOT_PATH}add-business`;
export const EDIT_BUSINESS_PATH = `${ROOT_PATH}edit-business`;

// philanthropic
export const PHILANTHROPIC_LIST_PATH = `${ROOT_PATH}philanthropic-list`;
export const ADD_PHILANTHROPIC_PATH = `${ROOT_PATH}add-philanthropic`;
export const EDIT_PHILANTHROPIC_PATH = `${ROOT_PATH}edit-philanthropic`;

// static page
export const STATIC_PAGE_LIST_PATH = `${ROOT_PATH}static-page-list`;
export const ADD_STATIC_PAGE_PATH = `${ROOT_PATH}add-static-page`;
export const EDIT_STATIC_PAGE_PATH = `${ROOT_PATH}edit-static-page`;

// recent-work
export const RECENT_WORK_LIST_PATH = `${ROOT_PATH}recent-work-list`;
export const ADD_RECENT_WORK_PATH = `${ROOT_PATH}add-recent-work`;
export const EDIT_RECENT_WORK_PATH = `${ROOT_PATH}edit-recent-work`;

// customer feedback
export const CUSTOMER_FEEDBACK_LIST_PATH = `${ROOT_PATH}customer-feedback-list`;

// contact
export const CONTACT_LIST_PATH = `${ROOT_PATH}contact-list`;

// site config
export const SITE_CONFIG_LIST_PATH = `${ROOT_PATH}site-config-list`;
export const ADD_SITE_CONFIG_PATH = `${ROOT_PATH}add-site-config`;
export const EDIT_SITE_CONFIG_PATH = `${ROOT_PATH}edit-site-config`;

// promotion
export const PROMOTION_LIST_PATH = `${ROOT_PATH}promotion-list`;
export const ADD_PROMOTION_PATH = `${ROOT_PATH}add-promotion`;
export const EDIT_PROMOTION_PATH = `${ROOT_PATH}edit-promotion`;

// blog
export const BLOG_LIST_PATH = `${ROOT_PATH}blog-list`;
export const ADD_BLOG_PATH = `${ROOT_PATH}add-blog`;
export const EDIT_BLOG_PATH = `${ROOT_PATH}edit-blog`;

// package
export const PACKAGE_LIST_PATH = `${ROOT_PATH}package-list`;
export const ADD_PACKAGE_PATH = `${ROOT_PATH}add-package`;
export const EDIT_PACKAGE_PATH = `${ROOT_PATH}edit-package`;

export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;

// Booking
export const BOOKING_LIST_PATH = `${ROOT_PATH}profile`;
export const EDIT_BOOKING_PATH = `${ROOT_PATH}edit-booking`;
export const BOOKING_DETAILS_PATH = `${ROOT_PATH}booking-details`;
