import React, { createContext, useState } from "react";
import api from "../services/Api";

export const ProductContext = createContext("ProductContext");

const ProductContextProvider = ({ children }) => {
    const [productList, setProductList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [summary, setSummary] = useState(null);

    const getProductList = async (params) => {
        api.product.getAll(
            { params, setLoading: setLoadingList },
            (response) => {
                setProductList(response.data.content);
                setTotalElements(response.data.totalElements);
                setSummary(response.data.summary);
            }
        );
    };

    return (
        <ProductContext.Provider
            value={{
                getProductList,
                productList,
                summary,
                loadingList,
                totalElements
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export default ProductContextProvider;
