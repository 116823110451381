// const API_VERSION = "1.0.0";

export const SITE_NAME = "Urelaa";
export const CURRENCY = "৳";

// const ROOT_URL = "http://46.28.44.148:8000";
// const ROOT_URL = "http://62.72.44.92:8011";
const ROOT_URL = "https://api.urelaa.com";
// const ROOT_URL = "http://localhost:8000";
// const ROOT_URL = "http://192.168.0.105:8000";

export const IMAGE_URL = `${ROOT_URL}/files`;
export const FILE_URL = `${ROOT_URL}/files`;
// const ROOT_URL = "http://192.168.0.111:8000";
// export const IMAGE_URL = "http://192.168.0.111:8000";
// export const FILE_URL = "http://192.168.0.111:8000";

const API_URL = `${ROOT_URL}`;

// auth
export const LOGIN_URL = `${API_URL}/login`;
export const REFRESH_TOKEN = `${API_URL}/login/refresh-token`;

// user
export const GET_ALL_USER = `${API_URL}/users`;
export const GET_USER_PROFILE = `${API_URL}/profile`;
export const GET_USER_BY_ID = `${API_URL}/users/id`;
export const CREATE_USER_URL = `${API_URL}/users/create`;
export const UPDATE_USER_URL = `${API_URL}/users/update`;
export const CHANGE_PASSWORD_URL = `${API_URL}/users/change-user-password`;
export const DELETE_USER_URL = `${API_URL}/users/delete`;
export const UPLOAD_PROFILE_IMAGE = `${API_URL}/upload/profile-image`;
export const UPLOAD_STORE_PROFILE_IMAGE = `${API_URL}/upload/profile-image`;
export const UPLOAD_STORE_LOGO_IMAGE = `${API_URL}/upload/profile-image`;

// customer
export const GET_ALL_CUSTOMERS = `${API_URL}/customers`;
export const GET_CUSTOMER_BY_ID = `${API_URL}/customers/id`;
export const CREATE_CUSTOMER_URL = `${API_URL}/customers/create`;
export const UPDATE_CUSTOMER_URL = `${API_URL}/customers/update`;
export const DELETE_CUSTOMER_URL = `${API_URL}/customers/delete`;

// team members
export const GET_ALL_TEAM_MEMBERS = `${API_URL}/teamMember`;
export const GET_TEAM_MEMBER_BY_ID = `${API_URL}/teamMember/id`;
export const CREATE_TEAM_MEMBER_URL = `${API_URL}/teamMember/create`;
export const UPDATE_TEAM_MEMBER_URL = `${API_URL}/teamMember/update`;
export const DELETE_TEAM_MEMBER_URL = `${API_URL}/teamMember/delete`;
export const UPLOAD_TEAM_MEMBER_IMAGE_URL = `${API_URL}/upload-image`;
// suit
export const GET_ALL_SUITS = `${API_URL}/suite`;
export const GET_SUIT_BY_ID = `${API_URL}/suite/id`;
export const CREATE_SUIT_URL = `${API_URL}/suite/create`;
export const UPDATE_SUIT_URL = `${API_URL}/suite/update`;
export const DELETE_SUIT_URL = `${API_URL}/suite/delete`;
export const UPLOAD_IMAGE_URL = `${API_URL}/upload-image`;

// banner
export const GET_ALL_BANNERS = `${API_URL}/banners`;
export const GET_BANNER_BY_ID = `${API_URL}/banners/id`;
export const CREATE_BANNER_URL = `${API_URL}/banners/create`;
export const UPDATE_BANNER_URL = `${API_URL}/banners/update`;
export const DELETE_BANNER_URL = `${API_URL}/banners/delete`;

// company
export const GET_ALL_COMPANIES = `${API_URL}/company`;
export const GET_COMPANY_BY_ID = `${API_URL}/company/id`;
export const CREATE_COMPANY_URL = `${API_URL}/company/create`;
export const UPDATE_COMPANY_URL = `${API_URL}/company/update`;
export const DELETE_COMPANY_URL = `${API_URL}/company/delete`;

// faq
export const GET_ALL_FAQ = `${API_URL}/faq`;
export const GET_FAQ_BY_ID = `${API_URL}/faq/id`;
export const CREATE_FAQ_URL = `${API_URL}/faq/create`;
export const UPDATE_FAQ_URL = `${API_URL}/faq/update`;
export const DELETE_FAQ_URL = `${API_URL}/faq/delete`;

// service
export const GET_ALL_SERVEICES = `${API_URL}/service`;
export const GET_SERVICE_BY_ID = `${API_URL}/service/id`;
export const CREATE_SERVICE_URL = `${API_URL}/service/create`;
export const UPDATE_SERVICE_URL = `${API_URL}/service/update`;
export const DELETE_SERVICE_URL = `${API_URL}/service/delete`;

// technology
export const GET_ALL_PHILANTHROPICS = `${API_URL}/technology`;
export const GET_PHILANTHROPIC_BY_ID = `${API_URL}/technology/id`;
export const CREATE_PHILANTHROPIC_URL = `${API_URL}/technology/create`;
export const UPDATE_PHILANTHROPIC_URL = `${API_URL}/technology/update`;
export const DELETE_PHILANTHROPIC_URL = `${API_URL}/technology/delete`;

// sass
export const GET_ALL_GALLERIES = `${API_URL}/saas`;
export const GET_GALLERY_BY_ID = `${API_URL}/saas/id`;
export const CREATE_GALLERY_URL = `${API_URL}/saas/create`;
export const UPDATE_GALLERY_URL = `${API_URL}/saas/update`;
export const DELETE_GALLERY_URL = `${API_URL}/saas/delete`;

// testimonial
export const GET_ALL_TESTIMONIALS = `${API_URL}/testimonial`;
export const GET_TESTIMONIAL_BY_ID = `${API_URL}/testimonial/id`;
export const CREATE_TESTIMONIAL_URL = `${API_URL}/testimonial/create`;
export const UPDATE_TESTIMONIAL_URL = `${API_URL}/testimonial/update`;
export const DELETE_TESTIMONIAL_URL = `${API_URL}/testimonial/delete`;

// Job
export const GET_ALL_JOBS = `${API_URL}/jobs`;
export const GET_JOB_BY_ID = `${API_URL}/jobs/id`;
export const CREATE_JOB_URL = `${API_URL}/jobs/create`;
export const UPDATE_JOB_URL = `${API_URL}/jobs/update`;
export const DELETE_JOB_URL = `${API_URL}/jobs/delete`;

// tributes
export const GET_ALL_TRIBUTES = `${API_URL}/tributes`;
export const GET_TRIBUTE_BY_ID = `${API_URL}/tributes/id`;
export const CREATE_TRIBUTE_URL = `${API_URL}/tributes/create`;
export const UPDATE_TRIBUTE_URL = `${API_URL}/tributes/update`;
export const DELETE_TRIBUTE_URL = `${API_URL}/tributes/delete`;

// Complimentary
export const GET_ALL_COMPLIMENTARIES = `${API_URL}/complimentaries`;
export const GET_COMPLIMENTARY_BY_ID = `${API_URL}/complimentaries/id`;
export const CREATE_COMPLIMENTARY_URL = `${API_URL}/complimentaries/create`;
export const UPDATE_COMPLIMENTARY_URL = `${API_URL}/complimentaries/update`;
export const DELETE_COMPLIMENTARY_URL = `${API_URL}/complimentaries/delete`;

// Accommodation
export const GET_ALL_ACCOMMODATION = `${API_URL}/accommodation`;
export const GET_ACCOMMODATION_BY_ID = `${API_URL}/accommodation/id`;
export const CREATE_ACCOMMODATION_URL = `${API_URL}/accommodation/create`;
export const UPDATE_ACCOMMODATION_URL = `${API_URL}/accommodation/update`;
export const DELETE_ACCOMMODATION_URL = `${API_URL}/accommodation/delete`;

// booking
export const GET_ALL_BOOKING = `${API_URL}/booking`;
export const GET_BOOKING_BY_ID = `${API_URL}/booking/id`;
export const UPDATE_BOOKING_URL = `${API_URL}/booking/update`;
export const DELETE_BOOKING_URL = `${API_URL}/booking/delete`;

// stores
export const GET_ALL_STORES = `${API_URL}/stores`;
export const GET_STORE_BY_ID = `${API_URL}/stores/id`;
export const CREATE_STORE_URL = `${API_URL}/stores/create`;
export const UPDATE_STORE_URL = `${API_URL}/stores/update`;
export const DELETE_STORE_URL = `${API_URL}/stores/delete`;

// static page
export const GET_ALL_STATIC_PAGES = `${API_URL}/static-page`;
export const GET_STATIC_PAGE_BY_ID = `${API_URL}/static-page/id`;
export const CREATE_STATIC_PAGE_URL = `${API_URL}/static-page/create`;
export const UPDATE_STATIC_PAGE_URL = `${API_URL}/static-page/update`;
export const DELETE_STATIC_PAGE_URL = `${API_URL}/static-page/delete`;
export const UPLOAD_STATIC_PAGE_IMAGE_URL = `${API_URL}/upload-image`;

// site config
export const GET_ALL_SITE_CONFIGS = `${API_URL}/site-config`;
export const GET_SITE_CONFIG_BY_ID = `${API_URL}/site-config/id`;
export const CREATE_SITE_CONFIG_URL = `${API_URL}/site-config/create`;
export const UPDATE_SITE_CONFIG_URL = `${API_URL}/site-config/update`;
export const DELETE_SITE_CONFIG_URL = `${API_URL}/site-config/delete`;
export const UPLOAD_SITE_CONFIG_LOGO_URL = `${API_URL}/upload-image`;

// contact
export const GET_ALL_CONTACT = `${API_URL}/contact-message`;
export const GET_CONTACT_BY_ID = `${API_URL}/contact-message/id`;
export const CREATE_CONTACT_URL = `${API_URL}/contact-message/create`;
export const UPDATE_CONTACT_URL = `${API_URL}/contact-message/update`;
export const DELETE_CONTACT_URL = `${API_URL}/contact-message/delete`;

// promotion
export const GET_ALL_PROMOTIONS = `${API_URL}/promotion`;
export const GET_PROMOTION_BY_ID = `${API_URL}/promotion/id`;
export const CREATE_PROMOTION_URL = `${API_URL}/promotion/create`;
export const UPDATE_PROMOTION_URL = `${API_URL}/promotion/update`;
export const DELETE_PROMOTION_URL = `${API_URL}/promotion/delete`;
export const UPLOAD_PROMOTION_IMAGE_URL = `${API_URL}/upload-image`;

// Blogs
export const GET_ALL_BLOGS = `${API_URL}/blogs`;
export const GET_BLOG_BY_ID = `${API_URL}/blogs/id`;
export const CREATE_BLOG_URL = `${API_URL}/blogs/create`;
export const UPDATE_BLOG_URL = `${API_URL}/blogs/update`;
export const DELETE_BLOG_URL = `${API_URL}/blogs/delete`;
export const UPLOAD_BLOG_IMAGE_URL = `${API_URL}/upload-image`;

// Business
export const GET_ALL_BUSINESSS = `${API_URL}/process`;
export const GET_BUSINESS_BY_ID = `${API_URL}/process/id`;
export const CREATE_BUSINESS_URL = `${API_URL}/process/create`;
export const UPDATE_BUSINESS_URL = `${API_URL}/process/update`;
export const DELETE_BUSINESS_URL = `${API_URL}/process/delete`;

export const GET_ALL_CUSTOMER_FEEDBACK = `${API_URL}/customer-feedback`;

// package
export const GET_ALL_PACKAGES = `${API_URL}/package`;
export const GET_PACKAGE_BY_ID = `${API_URL}/package/id`;
export const CREATE_PACKAGE_URL = `${API_URL}/package/create`;
export const UPDATE_PACKAGE_URL = `${API_URL}/package/update`;
export const DELETE_PACKAGE_URL = `${API_URL}/package/delete`;
export const UPLOAD_PACKAGE_IMAGE_URL = `${API_URL}/upload-image`;

// hero-section
export const GET_ALL_ACTIVITIES = `${API_URL}/activities`;
export const GET_ACTIVITY_BY_ID = `${API_URL}/activities/id`;
export const CREATE_ACTIVITY_URL = `${API_URL}/activities/create`;
export const UPDATE_ACTIVITY_URL = `${API_URL}/activities/update`;
export const DELETE_ACTIVITY_URL = `${API_URL}/activities/delete`;

export const GET_ALL_RECENT_WORKS = `${API_URL}/recent-work`;
export const GET_RECENT_WORK_BY_ID = `${API_URL}/recent-work/id`;
export const CREATE_RECENT_WORK_URL = `${API_URL}/recent-work/create`;
export const UPDATE_RECENT_WORK_URL = `${API_URL}/recent-work/update`;
export const DELETE_RECENT_WORK_URL = `${API_URL}/recent-work/delete`;

export const ACCESS_TOKEN = "u-pos-access-token";
export const STORE_ID = "u-pos-store-id";
export const PROFILE = "u-pos-profile";

export const ORDER_STATE_COLOR = {
    INITIATED: "",
    SUPPLIER_ACCEPTED: "cyan",
    DELIVERY_MAN_PICKED: "blue",
    COMPLETED: "green",
    CANCELED: "red"
};

export const GENDER_COLOR = {
    MALE: "blue",
    FEMALE: "green",
    OTHERS: "purple"
};
export const COMPANY = {
    name: "URELAA",
    address: "Dhaka Division, Bangladesh"
};

export const BACK_BUTTON_COLOR = "#3390FF";
// end
