const Permission = {
    CREATE_USER: "CREATE_USER",
    READ_SINGLE_USER: "READ_SINGLE_USER",
    READ_USERS: "READ_USERS",
    UPDATE_USERS: "UPDATE_USERS",
    DELETE_USERS: "DELETE_USERS",
    DISABLE_USERS: "DISABLE_USERS",
    READ_PROFILES: "READ_PROFILES",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    READ_SINGLE_PROFILE: "READ_SINGLE_PROFILE",
    READ_LITE_PROFILE: "READ_LITE_PROFILE",
    UPDATE_USERS_PASSWORD: "UPDATE_USERS_PASSWORD",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    // role
    CREATE_ROLE: "CREATE_ROLE",
    UPDATE_ROLE: "UPDATE_ROLE",
    READ_ROLES: "READ_ROLES",
    READ_SINGLE_ROLE: "READ_SINGLE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    // product
    CREATE_PRODUCT: "CREATE_PRODUCT",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    READ_PRODUCTS: "READ_PRODUCTS",
    READ_SINGLE_PRODUCT: "READ_SINGLE_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    // store
    CREATE_STORE: "CREATE_STORE",
    UPDATE_STORE: "UPDATE_STORE",
    READ_STORES: "READ_STORES",
    READ_SINGLE_STORE: "READ_SINGLE_STORE",
    DELETE_STORE: "DELETE_STORE",
    // generic
    CREATE_GENERIC: "CREATE_GENERIC",
    UPDATE_GENERIC: "UPDATE_GENERIC",
    READ_GENERICS: "READ_GENERICS",
    READ_SINGLE_GENERIC: "READ_SINGLE_GENERIC",
    DELETE_GENERIC: "DELETE_GENERIC",

    // customer
    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
    READ_CUSTOMERS: "READ_CUSTOMERS",
    READ_SINGLE_CUSTOMER: "READ_SINGLE_CUSTOMER",
    DELETE_CUSTOMER: "DELETE_CUSTOMER",

    // sale
    CREATE_SALE: "CREATE_SALE",
    UPDATE_SALE: "UPDATE_SALE",
    READ_SALES: "READ_SALES",
    READ_SINGLE_SALE: "READ_SINGLE_SALE",
    DELETE_SALE: "DELETE_SALE",

    // supplier
    CREATE_SUPPLIER: "CREATE_SUPPLIER",
    UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
    READ_SUPPLIERS: "READ_SUPPLIERS",
    READ_SINGLE_SUPPLIER: "READ_SINGLE_SUPPLIER",
    DELETE_SUPPLIER: "DELETE_SUPPLIER",
    // purchase
    CREATE_PURCHASE: "CREATE_PURCHASE",
    UPDATE_PURCHASE: "UPDATE_PURCHASE",
    READ_PURCHASES: "READ_PURCHASES",
    READ_SINGLE_PURCHASE: "READ_SINGLE_PURCHASE",
    DELETE_PURCHASE: "DELETE_PURCHASE",

    // static page
    CREATE_STATIC_PAGE: "CREATE_STATIC_PAGE",
    UPDATE_STATIC_PAGE: "UPDATE_STATIC_PAGE",
    READ_STATIC_PAGES: "READ_STATIC_PAGES",
    READ_SINGLE_STATIC_PAGE: "READ_SINGLE_STATIC_PAGE",
    DELETE_STATIC_PAGE: "DELETE_STATIC_PAGE"
};

export default Permission;
