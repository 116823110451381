import React, { createContext, useState } from "react";
import api from "../services/Api";

export const CustomerFeedbackContext = createContext("CustomerFeedbackContext");

const CustomerFeedbackContextProvider = ({ children }) => {
    const [customerFeedbackList, setCustomerFeedbackList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [summary, setSummary] = useState(null);

    const getCustomerFeedbackList = async (params) => {
        api.customerFeedback.getAll(
            { params, setLoading: setLoadingList },
            (response) => {
                setCustomerFeedbackList(response.data.content);
                setTotalElements(response.data.totalElements);
                setSummary(response.data.summary);
            }
        );
    };

    return (
        <CustomerFeedbackContext.Provider
            value={{
                getCustomerFeedbackList,
                customerFeedbackList,
                summary,
                loadingList,
                totalElements
            }}
        >
            {children}
        </CustomerFeedbackContext.Provider>
    );
};

export default CustomerFeedbackContextProvider;
