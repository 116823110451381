import { message } from "antd";
import React, { createContext, useState } from "react";
import { getDiscountAmount } from "../helpers/Utils";

export const PurchaseContext = createContext("PurchaseContext");

const PurchaseContextProvider = ({ children }) => {
    const [cart, setCart] = useState({
        items: [],
        subTotal: 0,
        discount: 0,
        commission: 0,
        profit: 0,
        finalPrice: 0,
        discountType: "Flat",
        discountAmount: 0,
        paymentAmount: 0,
        duePrice: 0
    });
    const [supplier, setSupplier] = useState(null);

    const addItem = (item, quantity) => {
        const exists = cart.items.find((e) => e.product?._id === item._id);
        if (exists) {
            message.error("This item already exists.");
            return;
        }
        const _cart = {
            ...cart,
            items: [
                ...cart.items,
                {
                    _id: item._id,
                    product: item,
                    quantity,
                    unitPrice: item.salePrice,
                    totalPrice: item.salePrice * quantity
                }
            ]
        };
        calculateTotalPrice(_cart);
    };

    const calculateTotalPrice = (_cart) => {
        let subTotal = 0;
        for (const i in _cart.items) {
            subTotal += _cart.items[i].totalPrice;
        }

        // Calculate commission based on subTotal (e.g., 10% commission)
        const commissionPercentage = 0.1; // 10% commission
        const commission = subTotal * commissionPercentage;

        // Calculate profit as the difference between the finalPrice and commission

        const discountAmount = getDiscountAmount(
            _cart.discountType,
            subTotal,
            _cart.discount
        );

        const profit = _cart.finalPrice - discountAmount - commission;
        // const paymentAmount = _cart.paymentAmount;
        const duePrice = _cart.finalPrice - _cart.paymentAmount;
        setCart({
            ..._cart,
            items: _cart.items,
            subTotal,
            discountAmount,
            commission, // Include commission in the cart
            finalPrice: subTotal - discountAmount - commission, // Subtract commission from finalPrice
            profit,
            // paymentAmount,
            duePrice
        });
    };

    const removeItem = (id) => {
        const items = [...cart.items.filter((e) => e.product?._id !== id)];
        calculateTotalPrice({ ...cart, items });
    };

    const changeItemQuantity = (id, quantity) => {
        if (quantity < 0) {
            message("Negative quantity is not allowed.");
            return;
        } else if (quantity === 0) {
            removeItem(id);
            return;
        }
        const items = [
            ...cart.items.map((e) => {
                if (e.product?._id === id) {
                    e.quantity = quantity;
                    e.totalPrice = e.product?.salePrice * quantity;
                }

                return e;
            })
        ];
        calculateTotalPrice({ ...cart, items });
    };

    const setDiscount = (discount) => {
        calculateTotalPrice({ ...cart, discount });
    };

    const changeDiscountType = (discountType) => {
        calculateTotalPrice({ ...cart, discountType });
    };

    const setPaymentAmount = (amount) => {
        calculateTotalPrice({ ...cart, amount });
    };

    // const changePaymentAmount = (amount) => {
    //     calculateDuePrice({ ...cart, amount });
    // };

    return (
        <PurchaseContext.Provider
            value={{
                items: cart.items,
                count: cart.items.length,
                cart,
                addItem,
                removeItem,
                changeItemQuantity,
                setDiscount,
                changeDiscountType,
                setPaymentAmount,
                setSupplier,
                supplier
            }}
        >
            {children}
        </PurchaseContext.Provider>
    );
};

export default PurchaseContextProvider;
